import 'owl.carousel';

const mainSlider = () => {
    const slider = $('.main-slider');

    if (!slider.hasClass('owl-carousel')) {
        slider.addClass('owl-carousel');
    }

    slider.owlCarousel({
        nav: true,
        navText : ['<i class="fa fa-chevron-left"></i>','<i class="fa fa-chevron-right"></i>'],
        loop: $('.main-slider').children().length > 1,
        items: 1,
        autoplay: true,
        smartSpeed: 500,
        addClassActive: true,
        onChanged: function (evt) {
            setTimeout(function(){
                $('.main-slider').find('.active .overlay').addClass('animated')
                $('.main-slider').find('.active').siblings().find('.overlay').removeClass('animated')
            }, 100);
        }
    });
}

const thumbSlider = () => {
    const slider = $('.thumbs-slider');

    if (!slider.hasClass('owl-carousel')) {
        slider.addClass('owl-carousel');
    }

    slider.owlCarousel({
        navigation: false,
        dots: true,
        loop: false,
        items: 1,
        autoplay: true,
    });
}

$(document).ready(() => {
    mainSlider();
    thumbSlider();

    if ($('body').hasClass('content-page')) {
        $('#header').addClass('no-slide');
        $('body').addClass('body-no-slide');
    }
});

