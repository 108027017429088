import moment from 'moment';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';

const dateFormat = 'DD/MM/YYYY';
const phpDateFormat = 'd/m/Y';

class BookingFormCalendars {
    constructor (startElement, endElement) {
        this.start = new Calendar(startElement, {
            onChange: () => this.onStartChange(),
            minDate: 'today',
            defaultDate: 'today',
            dateFormat: phpDateFormat
        });
        this.end = new Calendar(endElement, {
            dateFormat: phpDateFormat
        });
        this.init();
    }

    async init () {
        this.limitations = (await this.getLimitations()).data;
        this.onLimitationsChange();
        this.onStartChange();
    }

    getLimitations () {
        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.addEventListener('load', (response) => {
                response = response.target;
                if (response.status == 200) {
                    resolve(JSON.parse(response.responseText));
                } else {
                    reject(response.statusText);
                }
            });
            request.open('GET', `${window.base}api/booking/limitations?format=${phpDateFormat}`);
            request.send();

        });
    }

    onStartChange () {
        this.end.disableDatesInThePast(this.start.getFormattedDate());

        let disableDates = [];
        this.limitations.forEach((limitation) => {
            if (this.start.getFormattedDate() == limitation.date) {
                const from = new moment(limitation.date, dateFormat);
                const to = from.clone().add(limitation.days - 1, 'day');

                disableDates.push({
                    from: from.format(dateFormat),
                    to: to.format(dateFormat)
                });
            }
        });

        this.end.disableDates(disableDates);
    }

    onLimitationsChange () {
    }
}

class Calendar {
    constructor (element, options = {}) {
        this.element = element;
        this.flatpickr = flatpickr(element, options);
    }

    disableDatesInThePast (date) {
        this.flatpickr.set('minDate', date);
    }

    disableDates (options) {
        this.flatpickr.set('disable', options);
    }

    getDate () {
        return new moment(this.flatpickr.selectedDates[0]);
    }

    getFormattedDate () {
        const date = this.getDate();
        return date.format(dateFormat)
    }
}

$(document).ready(() => {
    const startCalendar = document.querySelector('.booking-form input[name="from"]');
    const endCalendar   = document.querySelector('.booking-form input[name="to"]');
    const bookingForm   = new BookingFormCalendars(startCalendar, endCalendar);
});


